import React, { useEffect, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import DetailView from "./DetailView";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import { api } from "../../components/global/api";

const NonAdmin = () => {
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;

  const handleSeeDetails = (detail) => {
    setSelectedDetail(detail);
  };

  const handleBack = () => {
    setSelectedDetail(null);
  };

  const getSchedule = async () => {
    try {
      const currentEmployee = currentUser?.employees;
      let company_id, user_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[user?.currentCompany]?.company_id;
        user_id = currentEmployee[user?.currentCompany]?.user_id;
      }
      const result = await api.get(baseURL + "/participationschedule-get/", {
        company_id: company_id,
        participation_id: user_id,
      });
      setData(result?.data);
    } catch (error) {
      if (error?.response?.status === 404) {
      }
      console.log(error);
    }
  };

  useEffect(() => {
    getSchedule();
  }, []);

  return selectedDetail ? (
    <>
      <button
        onClick={handleBack}
        className="mb-4 flex items-center italic text-base space-x-1"
      >
        <p className="text-gray-400 border-2 border-gray-200 rounded-full px-2 p-1 space-x-3 flex">
          <WestIcon className="text-gray-400" />
          <span>
            Touch Base{" "}
            <span className="font-semibold text-gray-700">
              / {selectedDetail?.name}
            </span>
          </span>
        </p>
      </button>
      <div
        className="flex flex-col p-4 h-screen md:h-[75vh]"
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
        }}
      >
        <DetailView data={selectedDetail} handleBack={handleBack} />
      </div>
    </>
  ) : (
    <div className="flex flex-col p-4 bg-[#F7F8FA] rounded-2xl w-full h-[75vh]">
      <div className="overflow-y-auto">
        <CustomScrollbar>
          {Object.entries(data).map(([timePeriod, meetings]) => (
            <div key={timePeriod}>
              <div className="flex items-center justify-center my-4">
                <div className="border-t border-gray-300 w-40"></div>
                <div className="border-2 border-gray-300 rounded-full px-24 py-2 mx-4 text-lg text-gray-500 font-sans">
                  {timePeriod?.charAt(0)?.toUpperCase() + timePeriod?.slice(1)}
                </div>
                <div className="border-t border-gray-300 w-40"></div>
              </div>
              {meetings?.length > 0 ? (
                meetings.map((meeting) => (
                  <div
                    key={meeting?.id}
                    className="p-4 bg-white rounded-lg mb-4"
                  >
                    <div className="flex flex-col w-full space-y-8 p-3">
                      <div className="flex items-center justify-between">
                        <div className="text-2xl font-semibold text-gray-600">
                          {meeting?.name}
                        </div>
                      </div>
                      <div className="flex items-center space-x-8 text-sm text-gray-600">
                        {[
                          {
                            src: "/assets/images/Template.png",
                            text: "Template type",
                            isSVG: false,
                          },
                          {
                            src: (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="pr-1"
                              >
                                <path
                                  d="M9.55 1H1V11.1538H9.55V1ZM19 14.8462H1V19H19V14.8462ZM19 1H13.15V4.69231H19V1ZM19 7.46154H13.15V11.1538H19V7.46154Z"
                                  stroke={
                                    meeting?.template_type ===
                                    "quarterly_template"
                                      ? "#92CAC1"
                                      : meeting?.template_type ===
                                        "monthly_template"
                                      ? "#F4DB77"
                                      : "#EEB0B0"
                                  }
                                  strokeWidth="1.5"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ),
                            text: meeting?.template_title,
                            isSVG: true,
                          },
                          {
                            src: "/assets/images/eos_icon.png",
                            text: "Assign by",
                            isSVG: false,
                          },
                        ]?.map((item, i) => (
                          <h1
                            key={i}
                            className="flex items-center justify-center"
                          >
                            {item?.isSVG ? (
                              item?.src
                            ) : (
                              <img
                                src={item?.src}
                                alt={item?.text}
                                className="pr-1"
                              />
                            )}
                            <p>{item?.text}</p>
                          </h1>
                        ))}
                        <div className="h-6 border-r-2 border-gray-300"></div>
                        <h1>{meeting?.user_details?.username}</h1>
                      </div>
                      <button
                        onClick={() => handleSeeDetails(meeting)}
                        className="bg-gray-100 p-2 rounded flex items-center justify-between"
                      >
                        <h1 className="text-xs text-gray-600 font-semibold">
                          See Details
                        </h1>
                        <EastIcon className="text-gray-600" />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500 py-4">
                  No meetings
                </div>
              )}
            </div>
          ))}
        </CustomScrollbar>
      </div>
    </div>
  );
};

export default NonAdmin;
