import React from "react";

const CustomScrollbar = ({ children }) => {
  return (
    <div style={scrollbarStyle} className="custom-scroll h-full">
      {children}
    </div>
  );
};

const scrollbarStyle = {
  overflowY: "auto", 
  // maxHeight: "full", 
  scrollbarWidth: "thin", 
  scrollbarColor: "#888 #f5f5f5", 
  WebkitOverflowScrolling: "touch", 
  "&::WebkitScrollbar": {
    width: "8px",
  },
  "&::WebkitScrollbarTrack": {
    backgroundColor: "#f5f5f5", // Track color
  },
  "&::WebkitScrollbarThumb": {
    backgroundColor: "#888", 
    borderRadius: "100px",
  },
  "&::WebkitScrollbarThumb:hover": {
    backgroundColor: "#555", 
  },
};

export default CustomScrollbar;