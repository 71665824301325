import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTitleData } from "../../stores/slices/userAuthSlice";
import ExecutiveInsights from "./ExecutiveInsights";
import StrategicReport from "./StrategicReport";
import ReportDrafts from "../Report/ReportDrafts";
import CreateReportModal from "../../components/Report/CreateReportModal";
import { handleCloseCreateReport } from "../../stores/slices/okrSlice";
import { baseURL } from "../../utils/config";
import { api } from "../../components/global/api";
import { handleUpdateDraftSlice } from "../../stores/slices/draftSlice";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../components/StepByStepGuide/context";
import ExportReports from "./ExportReports";

const Insights = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    setState,
    state: { tourActive, selectedtab },
  } = useAppContext();

  const initialTab = searchParams.get("tab") || "Executive Insights";
  const [selectedTab, setSelectedTab] = useState(initialTab);
  const [draftReports, setDraftReports] = useState();
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state.user);
  const [isDropdown, setIsDropdown] = useState(false);

  const handleResize = () => {
    setIsDropdown(window.innerWidth < 920); // Toggle dropdown based on screen size
  };

  React.useEffect(() => {
    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Listen for screen resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const handleClose = () => {
    dispatch(handleCloseCreateReport());
  };
  useEffect(() => {
    if (selectedtab) {
      setSelectedTab(selectedtab);
    }
  }, [selectedtab]);

  const fetchData = async () => {
    try {
      const currentCompany = currentUser?.employees[user?.currentCompany];
      const res = await api.get(baseURL + "/stats/", {
        company_id: currentCompany?.company_id,
      });
      setDraftReports(res.data || []);
      dispatch(handleUpdateDraftSlice(res.data || []));
    } catch (error) {
      console.log(error);
    }
    setSelectedTab("Reports");
  };

  useEffect(() => {
    setSearchParams({ tab: selectedTab });

    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: `People / ${selectedTab}`,
      })
    );
  }, [dispatch, selectedTab, setSearchParams]);

  const tabs = [
    "Executive Insights",
    "Strategic Report",
    "Reports",
    // "Comprehensive Report",
  ];

  return (
    <div className="w-screen md:w-full h-full  p-4 font-poppins">
      {isDropdown ? (
        <div className=" mb-4">
          <select
            value={selectedTab}
            onChange={(e) => setSelectedTab(e.target.value)}
            className="p-2 border border-gray-300 rounded"
            style={{
              width: window.innerWidth < 920 ? "100vw" : "300px",
              maxWidth: "100%",
            }}
          >
            {tabs?.map((tab) => (
              <option key={tab} value={tab}>
                {tab}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="flex items-center space-x-16 px-5">
          {tabs?.map((tab) => (
            <h1
              key={tab}
              onClick={() => setSelectedTab(tab)}
              className={`relative cursor-pointer ${
                selectedTab === tab ? "text-orange-500 font-sans" : ""
              }`}
            >
              {tab}
              {selectedTab === tab && (
                <span className="absolute left-0 bottom-[-10px] w-full h-[1px] bg-orange-500 transition-all duration-300 ease-in-out"></span>
              )}
            </h1>
          ))}
        </div>
      )}
      <div className="border-b-2 border-gray-300 mb-2 mt-2"></div>

      <div
        className="flex flex-col p-4 "
        style={{
          backgroundColor: "#F7F8FA",
          borderRadius: "16px",
          width: "100%",
          height: "73vh",
        }}
      >
        {selectedTab === "Executive Insights" && <ExecutiveInsights />}
        {selectedTab === "Strategic Report" && <StrategicReport />}
        {selectedTab === "Reports" && (
          <ReportDrafts
            draftReports={draftReports}
            setDraftReports={setDraftReports}
            fetchData={fetchData}
          />
        )}
        {/* {selectedTab === "Comprehensive Report" && <ExportReports />} */}
      </div>
      <CreateReportModal onCancel={handleClose} fetchData={fetchData} />
    </div>
  );
};

export default Insights;
