import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { baseURL } from "../../utils/config";
import { api } from "../../components/global/api";
import { CheckIcon } from "@chakra-ui/icons";
import WestIcon from "@mui/icons-material/West";

const AssignRoleSection = ({
  role,
  handleBackClick,
  handleSaveAssign,
  updates,
  setUpdates,
  handleCancel,
}) => {
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState(null);
  const [initialRoles, setInitialRoles] = useState(null);
  const [activities, setActivities] = useState();

  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const response = await api.get(baseURL + "/activity-list/");
        setActivities(response?.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchActivity();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.get(baseURL + "/role/", {
        company_id: currentUser?.employees[user?.currentCompany]?.company_id,
        role_id: role?.role_id,
      });

      setRoles(response?.data);
      setInitialRoles(JSON.parse(JSON.stringify(response?.data)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [role?.role_id, currentUser, user]);

  const handleCheckboxChange = (featureId, activityName, checked) => {
    setLoading(false);
    setRoles((prevRoles) =>
      prevRoles.map((role) => {
        if (role.feature_id === featureId) {
          return {
            ...role,
            activities: role.activities.map((activity) =>
              activity.activity_name === activityName
                ? { ...activity, activity_status: checked }
                : activity
            ),
          };
        }
        return role;
      })
    );

    setUpdates((prevUpdates) => {
      const role = roles.find((role) => role.feature_id === featureId);
      const roleActivity = role.activities.find(
        (activity) => activity.activity_name === activityName
      );

      const existingUpdateIndex = prevUpdates.findIndex(
        (update) =>
          update.feature_id === featureId &&
          update.activity_name === activityName
      );

      const newUpdate = {
        feature_id: featureId,
        activity_name: activityName,
        activity_status: checked,
        activity_id: roleActivity.activity_id,
      };

      if (existingUpdateIndex !== -1) {
        const updatedUpdates = [...prevUpdates];
        updatedUpdates[existingUpdateIndex] = newUpdate;
        return updatedUpdates;
      }
      return [...prevUpdates, newUpdate];
    });
  };

  const handleDeselectAll = () => {
    const updatedRoles = roles.map((role) => ({
      ...role,
      activities: role.activities.map((activity) => ({
        ...activity,
        activity_status: false,
      })),
    }));

    const updates = updatedRoles.flatMap((role) =>
      role.activities.map((activity) => ({
        feature_id: role.feature_id,
        activity_name: activity.activity_name,
        activity_status: false,
        activity_id: activity.activity_id,
      }))
    );

    setRoles(updatedRoles);
    setUpdates(updates);
    setLoading(false);
  };

  const hasChanges = () => {
    return JSON.stringify(roles) !== JSON.stringify(initialRoles);
  };

  return (
    <div>
      <div className="flex flex-col pb-5 border-b border-gray-300 overflow-x-hidden">
        <div className="flex items-center space-x-2 mb-4">
          <WestIcon
            className="cursor-pointer"
            fontSize="small"
            onClick={handleBackClick}
          />
          <h2 className="text-xl font-semibold text-center ">Assign Role</h2>
        </div>
        <div className="flex flex-col space-y-5 ">
          <table
            style={{
              borderCollapse: "collapse",
              margin: "0 auto",
              border: "1px solid black",
              fontSize: "0.9rem",
              width: "80%",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid black", padding: "6px" }}>
                  Feature
                </th>
                {activities?.map((activity, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      padding: "6px",
                    }}
                  >
                    {activity}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {roles?.map((role) => (
                <tr key={role.feature_id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      fontSize: "medium",
                    }}
                  >
                    {role.feature_name}
                  </td>
                  {activities?.map((activity) => {
                    const roleActivity = role.activities?.find(
                      (a) => a.activity_name === activity
                    );

                    return (
                      <td key={activity} style={{ border: "1px solid black" }}>
                        {roleActivity ? (
                          <div className="flex justify-center items-center">
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "4px",
                                border: roleActivity?.activity_status
                                  ? "2px solid #007bff"
                                  : "2px solid #ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: roleActivity?.activity_status
                                  ? "#007bff"
                                  : "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleCheckboxChange(
                                  role.feature_id,
                                  activity,
                                  !roleActivity?.activity_status
                                )
                              }
                            >
                              {roleActivity?.activity_status && (
                                <CheckIcon
                                  style={{
                                    fontSize: "16px",
                                    color: "#fff",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        ) : (
                          <p className="flex justify-center items-center">-</p>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-end space-x-4">
            <button
              className={`px-4 py-2 border-2 border-blue-500 text-blue-500 
              bg-white font-medium rounded hover:border-blue-700 hover:text-blue-700`}
              onClick={(e) => {
                e.preventDefault();
                handleCancel();
              }}
            >
              Cancel
            </button>

            <button
              className={`px-4 py-2 border-2 border-red-500 text-red-500 
              bg-white font-medium rounded hover:border-red-700 hover:text-red-700`}
              onClick={(e) => {
                e.preventDefault();
                handleDeselectAll();
              }}
            >
              Deselect
            </button>

            <button
              className={`px-4 py-2 text-white font-medium rounded 
              ${
                hasChanges() && !loading
                  ? "bg-blue-500 hover:bg-blue-700"
                  : "bg-blue-200 cursor-not-allowed"
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleSaveAssign(role?.role_id);
              }}
              disabled={!hasChanges() || loading}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignRoleSection;
