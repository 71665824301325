import React, { Suspense, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Cell, PieChart, Pie } from "recharts";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Card,
  Heading,
  Text,
  Flex,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import Nav from "../components/Nav";

import { Image } from "@chakra-ui/react";
import { MdChatBubbleOutline } from "react-icons/md";
import HomeChatbot from "../components/global/HomeChatbot";
import Screenshots from "../components/Screenshots";
import Pricing from "../components/Pricing";
import CardFeatures from "../components/CardFeatures";
import CTA from "../components/CTA";
import Footer from "../components/Footer";

const data1 = [
  { name: "Off track", value: 45 },
  { name: "Progressing", value: 40 },
  // { name: 'Group C', value: 300 },
  { name: "On track", value: 25 },
];
const COLORS_PIE = ["#FFB319", "#43B7DE", "#063F5D"];

const Home = () => {
  const [renderBar, setRenderBar] = useState(false);
  const [renderCart, setRenderCart] = useState(false);
  const [isChatbotOpen, setChatbotOpen] = useState(false);
  const [message, setMessage] = useState(false);

  const navigate = useNavigate();

  const handleChatbotButton = () => {
    setChatbotOpen(!isChatbotOpen);
  };

  const handleDemoClick = () => {
    navigate("/contactus");
  };

  const [percentage, setPercentage] = useState({
    offTrack: 0,
    progressing: 0,
    onTrack: 0,
  });

  useEffect(() => {
    const targetPercentage = 45;
    const targetPercentage1 = 40;
    const targetPercentage2 = 25;
    let currentPercentage = 0;

    const updatePercentage = () => {
      setPercentage((prevPercentage) => ({
        offTrack:
          prevPercentage.offTrack < targetPercentage
            ? prevPercentage.offTrack + 1
            : prevPercentage.offTrack,
        progressing:
          prevPercentage.progressing < targetPercentage1
            ? prevPercentage.progressing + 1
            : prevPercentage.progressing,
        onTrack:
          prevPercentage.onTrack < targetPercentage2
            ? prevPercentage.onTrack + 1
            : prevPercentage.onTrack,
      }));
      currentPercentage += 1;

      if (
        percentage.offTrack < targetPercentage ||
        percentage.progressing < targetPercentage1 ||
        percentage.onTrack < targetPercentage2
      ) {
        requestAnimationFrame(updatePercentage);
      }
    };

    const timeoutId = setTimeout(() => {
      requestAnimationFrame(updatePercentage);
    }, 35);

    return () => clearTimeout(timeoutId);
  }, [percentage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRenderBar(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const timeoutId2 = setTimeout(() => {
      setRenderCart(true);
    }, 4000);
    return () => clearTimeout(timeoutId2);
  }, []);
  const container = {
    visible: {
      transition: {
        staggerChildren: 2,
      },
    },
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (isChatbotOpen) {
  //       setChatbotOpen(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [isChatbotOpen]);

  const item = {
    hidden: { y: 100, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const [isMd] = useMediaQuery("(min-width: 48em)"); // 768px
  const [isLg] = useMediaQuery("(min-width: 62em)"); // 992px
  const [isXl] = useMediaQuery("(min-width: 80em)"); // 1280px

  const getSize = () => {
    if (isXl)
      return {
        width: 90,
        height: 90,
        innerRadius: 30,
        outerRadius: 45,
        fontSize: "0.75rem",
      };
    if (isLg)
      return {
        width: 50,
        height: 50,
        innerRadius: 15,
        outerRadius: 25,
        fontSize: "0.625rem",
      };
    if (isMd)
      return {
        width: 50,
        height: 50,
        innerRadius: 15,
        outerRadius: 25,
        fontSize: "0.625rem",
      };
    return {
      width: 90,
      height: 90,
      innerRadius: 10,
      outerRadius: 25,
      fontsize: "0.625rem",
    };
  };

  const { width, height, innerRadius, outerRadius, fontSize } = getSize();

  return (
    <div id="top">
      <Nav />

      <Box id="home" bg="white" color="black" maxW={"100%"} marginTop={"60px"}>
        {" "}
        {/*  maxHeight="100% */}
        <Flex
          direction={{ base: "column", md: "row" }}
          align="start"
          justify="space-between"
          paddingX={{ base: 4, md: 14 }}
          // mt={14}
          // mb={8}
        >
          <Box
            px={{ base: "6", sm: "12", md: "12" }}
            py={{ base: "6", sm: "8", md: "10", lg: "12" }}
          >
            <VStack
              align={{ base: "center", md: "start" }}
              spacing={{ base: 3, sm: 4 }}
              textAlign={{ base: "center", md: "start" }}
              mr={{ base: 0, md: 12 }}
            >
              <Heading
                as="h1"
                fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "32px" }}
                fontWeight={{ base: "normal", lg: "600" }}
                fontFamily="Poppins, sans-serif"
                className="text-basic"
              >
                Embrace The Power Of Modern OKR Management With A Cutting-Edge
                Platform For Laser Focus, Continuous Feedback, And Advanced
                Performance Insights.
              </Heading>
              <Text
                fontSize="20px"
                fontWeight="500"
                fontFamily="Poppins, sans-serif"
                color="rgba(1, 24, 36, 0.75)"
              >
                Set, meet and exceed your business OKRs with iMongu. Everything
                you need to support your people to succeed.
              </Text>

              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={{ base: 3, sm: 4 }}
                w="100%"
                justify={{ base: "center", md: "flex-start" }}
              >
                <Button
                  bg="#F7AD18"
                  color="#001621"
                  size="lg"
                  to="/register"
                  onClick={handleDemoClick}
                  _hover={{ bg: "#e39814" }}
                  gap={2}
                  w={{ base: "full", sm: "auto" }}
                >
                  Book A Demo <ArrowForwardIcon />
                </Button>
                <Button
                  colorScheme="#001621"
                  size="lg"
                  to="/register"
                  variant="outline"
                  onClick={() => {
                    document
                      .getElementById("pricing")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                  w={{ base: "full", sm: "auto" }}
                >
                  View Pricing
                </Button>
              </Flex>
            </VStack>
          </Box>

          <Box
            marginBottom={28}
            minW={{ md: "35%", lg: "35%", xl: "50%" }}
            display={{ base: "none", md: "block" }}
            style={{
              backgroundImage: `url('/assets/images/homeImage.svg')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              borderRadius: "40px",
            }}
          >
            <motion.ul
              className="container"
              variants={container}
              initial="hidden"
              animate="visible"
            >
              <Flex direction={{ base: "column", md: "row" }}>
                <Box>
                  <motion.li className="item" variants={item}>
                    <Box
                      mt={10}
                      // border={"1px dashed #43B7DE"}
                      borderRadius={"1rem"}
                      position={"relative"}
                      // h={"200px"}
                      minH={{ md: "100px", lg: "100px", xl: "200px" }}
                      // w={"260px"}
                      minW={{ md: "200px", lg: "200px", xl: "280px" }}
                      // minW={"320px"}
                      top={{ lg: "30px", xl: "20px", "2xl": "-10px" }}
                      // top={"-10px"}
                      left={"10px"}
                    >
                      <img
                        src="/assets/images/homesubgoal.svg"
                        className="animated-back-element"
                        alt="subgoal"
                        decoding="asynchronous"
                        style={{
                          width: "340px",
                          height: "65px",
                        }}
                      />
                      {/* <img src="/assets/images/homecircle1.svg" /> */}
                    </Box>
                  </motion.li>
                  <motion.li>
                    <Box className="md:max-w-sm lg:max-w-md xl:max-w-md 2xl:max-w-xl">
                      <img
                        decoding="asynchronous"
                        src="/assets/images/homecircle2.svg"
                        alt="circle"
                        width={{
                          base: "100%",
                          md: "20rem",
                          lg: "24rem",
                          xl: "28rem",
                        }}
                        height={"auto"}
                      />
                    </Box>
                  </motion.li>
                  <motion.li className="item" variants={item}>
                    <Flex
                      position={"relative"}
                      justifyContent={"space-between"}
                      left={"-100px"}
                      top={"-100px"}
                    >
                      <Image
                        decoding="asynchronous"
                        src={"/assets/images/3star.svg"}
                      />
                    </Flex>
                    <Box
                      mt={10}
                      border={"1px dashed #43B7DE"}
                      borderRadius={"1rem"}
                      position={"relative"}
                      // h={"195px"}
                      h={{ md: "150px", lg: "150px", xl: "195px" }}
                      w={{ md: "150px", lg: "150px", xl: "190px" }}
                      // w={"190px"}
                    >
                      <Card
                        ml="auto"
                        my={5}
                        // maxW={"190px"}
                        // maxH={"195px"}
                        maxW={{ md: "150px", lg: "150px", xl: "190px" }}
                        maxH={{ md: "150px", lg: "150px", xl: "195px" }}
                        position={"absolute"}
                        zIndex={"1"}
                        top={"-40px"}
                        right={"30px"}
                      >
                        <h2
                          className="font-medium tracking-tighter pl-2 text-left font-poppins text-lg ml-3 leading-28"
                          color="#001621"
                        >
                          Goals
                        </h2>
                        <div className=" md:w-[70px] lg:w-[70px] xl:w-[90px] flex items-center transform translate-x-1/2 justify-center">
                          <div className="relative mb-2 mt-2">
                            <PieChart
                              // width={90}
                              //  minW={{ md: "50px", lg: "50px", xl: "90px" }}
                              //  minH={{ md: "50px", lg: "50px", xl: "90px" }}
                              width={width}
                              height={height}
                            >
                              <Pie
                                data={data1}
                                //   cx={150}
                                //   cy={150}
                                // innerRadius={30}
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                // outerRadius={45}
                                startAngle={450}
                                endAngle={90}
                                // fill="#8884D8"
                                // paddingAngle={5}
                                // dataKey="value"
                              >
                                {data1.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS_PIE[index % COLORS_PIE.length]}
                                    stroke="0"
                                    //   cornerRadius={entry.radiusadius}
                                    //   cornerRadius={10}
                                  />
                                ))}
                              </Pie>
                            </PieChart>
                            <div className="absolute md:left-[12px] md:top-[9px] lg:left-[12px] lg:top-[9px] xl:left-[24px] xl:top-[20px]">
                              <div className="flex flex-col items-center justify-center">
                                <span className="font-black lg:text-lg md:text:md xl:text-xl">
                                  {percentage?.offTrack}
                                </span>
                                <span className="hidden lg:hidden xl:block text-slate-500">
                                  Goals
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-center m-1 gap-3">
                          <div>
                            <div className="flex items-center gap-2">
                              <div className="rounded-full bg-orange-400 h-2 w-2" />
                              <h6
                                style={{ fontSize }}
                                className=" text-slate-500"
                              >
                                Off track
                              </h6>
                            </div>
                            <h6 className="text-sm ml-3 font-bold">
                              {percentage?.offTrack}%
                            </h6>
                          </div>
                          <div>
                            <div className="flex items-center gap-1">
                              <div className="rounded-full bg-cyan-500 h-2 w-2" />
                              <h6
                                style={{ fontSize }}
                                className=" text-slate-500"
                              >
                                Progressing
                              </h6>
                            </div>
                            <h6 className="text-sm ml-3 font-bold">
                              {percentage?.progressing}%
                            </h6>
                          </div>
                          <div>
                            <div className="flex items-center gap-1">
                              <div className="rounded-full bg-black h-2 w-2" />
                              <h6
                                style={{ fontSize }}
                                className=" text-slate-500"
                              >
                                On track
                              </h6>
                            </div>
                            <h6 className="text-sm ml-3 font-bold">
                              {percentage?.onTrack}%
                            </h6>
                          </div>
                        </div>
                      </Card>
                    </Box>
                  </motion.li>
                </Box>
                <Flex direction={{ base: "column", md: "row" }}>
                  <Box>
                    <img
                      decoding="asynchronous"
                      src="/assets/images/homecircle1.svg"
                      alt="circle"
                    />
                  </Box>
                  <Box>
                    <motion.li
                      className="item"
                      variants={item}
                      style={{
                        marginLeft: { base: 0, md: "-8px", lg: "-8px", xl: 0 },
                      }}
                    >
                      {renderCart && (
                        <Box
                          bg={"#419EBD"}
                          p={2}
                          minW={{
                            base: "50px",
                            md: "50px",
                            lg: "50px",
                            xl: "200px",
                          }}
                          minH={{ base: "20px", md: "20px", lg: "30px" }}
                          borderRadius={"10px"}
                          // mt={3}
                          // mx={4}
                          mt={{ base: 3, md: 3, lg: 3 }}
                          mx={{ base: 0, md: -2, lg: -2, xl: 4 }}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          className="animated-element relative"
                        >
                          <Box marginLeft={2}>
                            <div
                              style={{
                                right: "2%",
                                top: "-1%",
                              }}
                              className="z-1 absolute  w-20 md:hidden lg:hidden xl:block xl:w-20"
                            >
                              <svg
                                width="100"
                                height="57"
                                viewBox="0 0 100 57"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 3.72633C5.53501 1.95777 14.3003 0.542793 18.9655 4.78747C24.7977 10.0933 18.9655 37.3435 10.2522 31.4368C1.53753 25.5301 33.2133 7.02574 43.6169 6.3793C54.0217 5.73273 64.5622 10.0933 65.6227 27.6027C66.4703 41.6101 66.5058 54.3087 66.1523 59.4377C72.514 42.2821 87.3598 11.6851 97.4327 6.3793"
                                  stroke="#001621"
                                  strokeWidth="3.16605"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <Flex align="center" gap={3}>
                              <Image
                                src={"/assets/images/pic_2.png"}
                                alt="Your Image"
                                maxW={{
                                  base: "100%",
                                  md: "30%",
                                  lg: "30%",
                                  xl: "80%",
                                }}
                              />
                              <Text
                                className="md:text-xs lg:text-xs xl:text-lg"
                                color={"#FFFFFF"}
                                textAlign="center"
                              >
                                Emerson Rosser
                              </Text>
                            </Flex>
                            <Flex gap={3}>
                              <Image
                                src={"/assets/images/market.svg"}
                                alt="Your Image"
                                width={"10%"}
                              />
                              <Text
                                className="md:text-xs lg:text-xs xl:text-xl"
                                color={"#FFFFFF"}
                              >
                                Marketing
                              </Text>
                              <Image
                                src={"/assets/images/day2.svg"}
                                alt="Your Image"
                              />
                              <Text
                                className="md:text-xs lg:text-xs xl:text-xl"
                                color={"#FFFFFF"}
                              >
                                3d ago
                              </Text>
                            </Flex>
                          </Box>
                        </Box>
                      )}
                    </motion.li>
                    <motion.li className="item" variants={item}>
                      <Box
                        mt={10}
                        borderRadius={"1rem"}
                        position={"relative"}
                        // h={"205px"}
                        // minW={"260px"}
                        minH={{ md: "160px", lg: "160px", xl: "205px" }}
                        minW={{ md: "130px", lg: "130px", xl: "260px" }}
                        top={{ md: "150px", lg: "150px", xl: "250px" }}
                        // top={"280px"}
                      >
                        <img
                          src="/assets/images/homeprogress.png"
                          alt="progres"
                        />
                      </Box>
                    </motion.li>
                    <motion.li>
                      {renderCart && (
                        <Box
                          position={"relative"}
                          top={{ md: "150px", lg: "150px", xl: "200px" }}
                          zIndex={1}
                        >
                          <img
                            loading="lazy"
                            decoding="asynchronous"
                            src="/assets/images/homecircle3.svg"
                            alt="circle"
                          />
                        </Box>
                      )}
                    </motion.li>
                  </Box>
                </Flex>
              </Flex>
            </motion.ul>
          </Box>
        </Flex>
        <Screenshots />
        <CardFeatures />
        <Pricing showTitle="true" />
        <CTA />
      </Box>
      <Footer />

      <div
        className="z-auto flex flex-col  bottom-5 right-5 p-2 sticky"
        style={{
          zIndex: "10500",
        }}
      >
        <Popover
          isLazy
          placement="top-end"
          isOpen={isChatbotOpen}
          onClose={handleChatbotButton}
        >
          <PopoverTrigger>
            <div className="flex flex-col justify-end items-end">
              {!isChatbotOpen && message && (
                <div
                  className=" mr-5 mb-2 z-20 text-sm shadow-sm right-10 rounded-t-md rounded-bl-md p-3"
                  style={{ backgroundColor: "#FFD580" }}
                >
                  Hi 👋! I'm Joyce, your chatbot assistant. How may I help you?
                </div>
              )}
              <Button
                p={2}
                borderRadius="full"
                backgroundColor="#063F5D"
                color="white"
                _hover={{ padding: "4px", bg: "#042a45" }}
                onMouseEnter={() => setMessage(true)}
                onMouseLeave={() => setMessage(false)}
                onClick={handleChatbotButton}
                className="relative flex justify-end"
              >
                <span className="flex justify-end items-center gap-x-2">
                  {isChatbotOpen ? (
                    <KeyboardArrowDownIcon className="text-white" />
                  ) : (
                    <MdChatBubbleOutline className="text-white" />
                  )}
                </span>
              </Button>
            </div>
          </PopoverTrigger>
          <PopoverContent
            borderColor="white"
            className={`relative mr-5 text-sm rounded-t-md rounded-bl-md 
    ${window.innerWidth <= 768 ? "w-[75vw] h-[70vh]" : "w-[25vw]"} 
    min-w-[240px] max-h-[70vh] transition-all duration-[1s] ease-[cubic-bezier(0.42, 0, 0.58, 3)]`}
            style={{
              zIndex: "10500",
            }}
          >
            {/* <div
                // style={{
                //   transition:
                //     "width 1s cubic-bezier(0.42, 0, 0.58, 1), height 1s cubic-bezier(0.42, 0, 0.58, 1)",
                // }}
              > */}
            <HomeChatbot handleClose={handleChatbotButton} />
            {/* </div> */}
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default Home;
