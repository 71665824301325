// import { Page, Paragraph, theme } from '@gilbarbara/components';
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
// import Header from './Header';
import Joyride from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { PiStarFour } from "react-icons/pi";
import { useState } from "react";
import { useAppTouchBaseContext } from "./context";

export default function MultiRouteTouchBaseWrapper() {
  const {
    setTouchBaseState,
    TouchBasestate: { run, stepIndex, steps, selectedtab },
  } = useAppTouchBaseContext();
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  useMount(() => {
    setTouchBaseState({
      steps: [
        {
          target: ".first_touchbase_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-1: Create a TouchBase
              </Heading>
              <Text
                fontSize="sm"
                lineHeight="1.6"
                color="gray.700"
                mb={4}
                textAlign="justify"
              >
                A Touch-Base is a regular meeting between a manager and a direct
                report to review the status of their Objectives and Key Results
                (OKRs). This meeting is crucial for maintaining alignment,
                ensuring progress, and addressing any obstacles that may arise.
                <br />
                <br />
                Typically, a Touch-Base may occur:
                <br />
                <ul>
                  <li>
                    <strong>Weekly:</strong> Ideal for fast-paced environments
                    with rapidly changing priorities.
                  </li>
                  <li>
                    <strong>Bi-weekly:</strong> Suitable for environments with
                    moderate changes and stable projects.
                  </li>
                  <li>
                    <strong>Monthly:</strong> Appropriate for longer-term
                    projects and stable work environments.
                  </li>
                </ul>
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".second_touchbase_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-2: Choose/Create a Template
              </Heading>
              <Text>
                Choose the default Templates or create one by editing these
                default templates.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".third_touchbase_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-3: Create a Schedule
              </Heading>
              <Text>
                You have the flexibility to create a new schedule by selecting
                your desired date, time, and users, allowing you to customize
                recurring meetings daily, weekly, or in various other intervals
                to suit your needs.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
        {
          target: ".fourth_touchbase_tour",
          content: (
            <>
              <Heading as="h4" size="md" mb={3}>
                Step-4: View TouchBase overview
              </Heading>
              <Text>
                Here, you can review all the responses provided by users and add
                comments to facilitate discussion and feedback.
              </Text>
            </>
          ),
          disableBeacon: true,
        },
      ],
    });
  });

  const handleCallback = (data) => {
    const { action, index, lifecycle, type } = data;

    if (type === "step:after") {
      switch (index) {
        case 0:
          if (action === "next") {
            setTouchBaseState({ stepIndex: index + 1, run: true });
          } else if (action === "prev") {
            setTouchBaseState({ run: false });
            navigate("/touchbase/templates");
          }
          break;
        case 1:
          if (action === "next") {
            setTouchBaseState({ run: true, stepIndex: index + 1 });
            navigate("/touchbase/schedule", { replace: true });
          } else if (action === "prev") {
            setTouchBaseState({ run: true, stepIndex: index - 1 });
            navigate("/touchbase/templates");
          }
          break;

        case 2:
          if (action === "next") {
            setTouchBaseState({
              run: true,
              stepIndex: index + 1,
              selectedtab: "Admin",
            });
            navigate("/company/touchbase", {
              replace: true,
            });
          } else if (action === "prev") {
            setTouchBaseState({
              run: true,
              stepIndex: index - 1,
            });
            navigate("/touchbase/schedule", { replace: true });
          }
          break;

        case 3:
          if (action === "next") {
            setTouchBaseState({ run: false, stepIndex: 0, tourActive: false });
            localStorage.removeItem("startTouchBaseTour");
            navigate("/touchbase/templates");
          } else if (action === "prev") {
            setTouchBaseState({
              run: true,
              stepIndex: index - 1,
            });
            navigate("/company/touchbase", {
              replace: true,
            });
          }
          break;

        default:
          break;
      }
    } else if (
      action === "skip" ||
      action === "reset" ||
      lifecycle === "complete"
    ) {
      setTouchBaseState({ run: false, stepIndex: 0, tourActive: false });
      localStorage.removeItem("startTouchBaseTour");
      navigate("/touchbase/templates");
    }
  };

  // const handleCallback = (data) => {
  //   const { action, index, lifecycle, type } = data;

  //   if (type === "step:after") {
  //     if (action === "next") {
  //       setTouchBaseState({
  //         stepIndex: index + 1,
  //         run: false,
  //       });

  //       switch (index) {
  //         case 0:
  //           navigate("/touchbase/templates");
  //           break;
  //         case 1:
  //           navigate("/touchbase/schedule", { replace: true });
  //           break;
  //         case 2:
  //           navigate("/company/touchbase", { replace: true });
  //           break;
  //         case 3:
  //           localStorage.removeItem("startTouchBaseTour");
  //           navigate("/home");
  //           break;
  //       }
  //     } else if (action === "prev") {
  //       setTouchBaseState({
  //         stepIndex: index - 1,
  //         run: true,
  //       });

  //       switch (index) {
  //         case 1:
  //           navigate("/touchbase/templates");
  //           break;
  //         case 2:
  //           navigate("/touchbase/schedule", { replace: true });
  //           break;
  //         case 3:
  //           navigate("/company/touchbase", { replace: true });
  //           break;
  //       }
  //     }
  //   } else if (
  //     action === "skip" ||
  //     action === "reset" ||
  //     lifecycle === "complete"
  //   ) {
  //     setTouchBaseState({ run: false, stepIndex: 0, tourActive: false });
  //     localStorage.removeItem("startTouchBaseTour");
  //     navigate("/home");
  //   }
  // };

  return (
    <Box>
      {/* <Header /> */}
      {/* <Outlet /> */}
      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        hideCloseButton
        showProgress
        disableCloseOnEsc
        disableOverlayClose
        showSkipButton
        disableScrolling
        styles={{
          options: {
            arrowColor: "#E5EFF5", // Light color for the arrow
            backgroundColor: "#E5EFF5", // Solid light background color
            primaryColor: "#FFB319", // Bright primary color for highlights
            textColor: "#063F5D", // Darker text color for readability
            overlayColor: "rgba(0, 0, 0, 0.5)", // Dark overlay for focus
            width: 600, // Increased width for more space
            height: 280, // Balanced height for a card look
            padding: "20px", // Moderate padding for spacing
            borderRadius: "12px",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)", // Enhanced shadow for depth
            zIndex: 1000, // Layer above other content
          },
        }}
      />
    </Box>
  );
}
