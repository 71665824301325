import React from "react";
import "animate.css";
import { Image, Heading, Box } from "@chakra-ui/react";

export default function Screenshots() {
  return (
    <>
      <Box
        id="screenshots"
        position="relative"
        className="m-8 p-4 flex flex-col justify-between"
        style={{
          backgroundColor: "#DEF7FF",
          minHeight: "580px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        <Heading
          as="h2"
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
          color={"#14285a"}
          textAlign={"center"}
          className="mb-5"
        >
          Your Goals, Performance Insights And Chatbot Assistance All At Your
          Fingertips.
        </Heading>

        {/* Screenshot 1 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss5.png"}
          alt="In progress"
          position="absolute"
          right={{ base: "10%", md: "15%" }}
          zIndex="1"
          width={{ base: "40%", md: "20%" }}
          top={{ base: "30%", md: "35%" }}
        />

        {/* Screenshot 2 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss6.png"}
          alt="Notification"
          position="absolute"
          left={{ base: "5%", sm: "10%" }}
          zIndex="1"
          width={{ base: "25%", sm: "20%", md: "15%" }}
          top={{ base: "55%", sm: "45%" }}
        />

        {/* Screenshot 3 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss7.png"}
          alt="Key result"
          position="absolute"
          right={{ base: "5%", md: "10%" }}
          zIndex="1"
          width={{ base: "20%", md: "10%" }}
          top={{ base: "55%", sm: "45%" }}
        />

        {/* Screenshot 4 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss1.png"}
          alt="Image"
          position="absolute"
          left="50%"
          bottom={{ base: "-10%", md: "-20%" }}
          transform="translateX(-50%)"
          zIndex="1"
          width={{ base: "90%", md: "70%" }}
          border="3px solid black"
          borderRadius="5%"
        />

        {/* Screenshot 5 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss4.png"}
          alt="Outstanding"
          position="absolute"
          left={{ base: "5%", sm: "10%" }}
          zIndex="1"
          width={{ base: "10%", sm: "20%" }}
          bottom={{ base: "5%", sm: "6%" }}
        />

        {/* Screenshot 6 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss3.png"}
          alt="Insights"
          position="absolute"
          left={{ base: "1%", sm: "5%" }}
          zIndex="1"
          width={{ base: "50%", sm: "45%" }}
          bottom={{ base: "-30%", sm: "-40%" }}
        />

        {/* Screenshot 7 */}
        <Image
          loading="lazy"
          decoding="asynchronous"
          src={"/assets/images/ss2.png"}
          alt="Chatbot"
          position="absolute"
          right={{ base: "-5%", sm: "-2%" }}
          zIndex="1"
          width={{ base: "60%", sm: "50%", md: "37%" }}
          height={{ base: "70%", sm: "85%" }}
          bottom={{ base: "-30%", sm: "-29%" }}
        />
      </Box>
    </>
  );
}
