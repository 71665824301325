import React, { useEffect, useState } from "react";

import { Modal } from "antd";
import { baseURL } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import TeamModal from "../../components/People/Teams/TeamModal";
import { TeamTables } from "../../components/People/Teams/TeamTables";
import TeamEditModal from "../../components/People/Teams/TeamEditModal";
import { useNavigate } from "react-router-dom";
import {
  handleUpdatePlan,
  updateTitleData,
} from "../../stores/slices/userAuthSlice";
import { useMount } from "react-use";
import { useAppContext } from "../../components/StepByStepGuide/context";
import {
  handleCreateCloseTeamDrawer,
  handleCreateTeamDrawer,
} from "../../stores/slices/okrSlice";
import FilterTeams from "./FilterTeams";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { api } from "../../components/global/api";
import { toast, ToastContainer } from "react-toastify";
import CustomScrollbar from "../../components/global/CustomScrollBar";

export const Teams = () => {
  // const [showLoader, setLoader] = useState(true);
  const {
    setState,
    state: { tourActive },
  } = useAppContext();

  const [formData, setformData] = useState({
    owner: "",
    // parent_team: "",
    manager: null,
    name: "",
  });
  const [openEditModel, setOpenEditModel] = useState(false);
  const [originalFormData, setOriginalFormData] = useState({});
  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;
  const okrSlice = useSelector((state) => state?.okr);
  const currentPlan = user?.currentPlan;
  const currentCompany = useSelector((state) => state.user?.currentCompany);
  const [data, setdata] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formDataFilter, setformDatafilter] = useState({
    name: "",
  });

  if (
    currentPlan?.plan_name === "Free" &&
    !currentPlan?.free_trial_status &&
    roleAccess?.["Billing"]?.Access
  ) {
    navigate("/settings?tab=Billing");
  }

  useMount(() => {
    if (tourActive) {
      setState({ run: true, stepIndex: 7 });
      // }, 1200);
    }
  });

  const handleClick = () => {
    if (user?.roleAccess?.Teams?.Invite) {
      dispatch(handleCreateTeamDrawer());
    } else {
      toast.error("You dont have access to this feature.");
    }
  };

  const [visibleFields, setVisibleFields] = useState(["Name"]);

  const values = ["Name"];
  const addField = (field) => {
    setVisibleFields((prevVisibleFields) => {
      if (!prevVisibleFields.includes(field)) {
        return [...prevVisibleFields, field];
      }
      return prevVisibleFields;
    });
  };

  const removeField = (field, name) => {
    setformDatafilter((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData[name] = "";
      return updatedFormData;
    });
    setVisibleFields((prevVisibleFields) => {
      return prevVisibleFields.filter((f) => f !== field);
    });
  };

  const fetchData = async () => {
    const params = { team_name: formDataFilter?.name };
    try {
      const currentEmployee = currentUser?.employees;
      let company_id;
      if (currentEmployee && currentEmployee?.length) {
        company_id = currentEmployee[currentCompany]?.company_id;
      }
      const response = await api.get(baseURL + "/create-team/", {
        company_id: company_id,
        fields: JSON.stringify(params),
      });
      setdata(response.data);
    } catch (error) {
      //  setError(error);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [formDataFilter]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleOk = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    dispatch(handleCreateCloseTeamDrawer());
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    dispatch(handleCreateCloseTeamDrawer());
    setformData({
      owner: "",
      // parent_team: "",
      manager: null,
      name: "",
    });
  };
  const handleEditCancel = () => {
    // toast.dismiss();
    setOpenEditModel(false);
    setformData({
      owner: "",
      // parent_team: "",
      manager: null,
      name: "",
    });
  };
  const handleDeleteButtonClick = () => {
    setShowBelowDiv(false);
  };

  const handleDeleteTeam = async (team_id) => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const res = await api.delete(baseURL + "/create-team/", {
        team_id: team_id,
        company_id: currentCompany?.company_id,
      });
      if (res.status === 202) {
        getCurrentPlanData();
        toast.success("Team deleted Successfully");
      } else {
        toast.error("Please Try Again");
      }
    } catch (error) {
      console.log(error);
      toast.error("Please Try Again");
    } finally {
      fetchData();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const changes = {};
    Object.keys(formData).forEach((key) => {
      if (
        key !== "team_id" &&
        key !== "members" &&
        formData[key] !== originalFormData[key]
      ) {
        changes[key] = formData[key];
      }
    });

    if (formData?.members !== originalFormData?.members) {
      changes.users = formData?.members;
    }
    try {
      const currentEmployee = currentUser?.employees;
      const currentCompanyIndex = user?.currentCompany;
      const res = await api.put(baseURL + "/create-team/", {
        team_id: formData?.team_id,
        company_id: currentEmployee[currentCompanyIndex]?.company_id,
        ...changes,
      });
      if (res?.status === 200) {
        toast.success("Team Updated Successfully");
        handleEditCancel();
        fetchData();
      } else {
        toast.error("Please Try Again");
        handleEditCancel();
      }
    } catch (error) {
      console.log(error);
      toast.error("Please Try Again");
      handleEditCancel();
    }
    handleEditCancel();
    setLoading(false);
  };

  const handleOpenEditModel = (tempData) => {
    let manager_id;
    let employees = [];

    if (tempData?.manager?.length) {
      manager_id = tempData?.manager[0]?.user_id;
    }

    if (tempData?.employees?.length) {
      employees = tempData?.employees?.map((employee) => ({
        user_id: employee?.user_id,
        role_id: employee?.role,
      }));
    }
    setformData({
      ...tempData,
      members: employees,
    });

    setOriginalFormData({
      ...tempData,
      members: employees,
    });

    setOpenEditModel(true);
  };

  useEffect(() => {
    dispatch(
      updateTitleData({
        title: "People",
        ClickLink: "Company / Teams",
      })
    );
  }, []);

  const [showBelowDiv, setShowBelowDiv] = useState(false);

  return (
    <div className="h-full overflow-y-auto flex flex-col p-2 bg-white">
      <CustomScrollbar>
        <TeamTables
          fetchData={fetchData}
          data={data}
          setOpenEditModel={setOpenEditModel}
          handleOpenEditModel={handleOpenEditModel}
          handleDeleteTeam={handleDeleteTeam}
        />
      </CustomScrollbar>

      <Modal
        title=""
        open={okrSlice?.openCreateTeamDrawer}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        footer={null}
      >
        <TeamModal
          fetchData={fetchData}
          formData={formData}
          setformData={setformData}
          changeHandeler={changeHandeler}
          handleCancel={handleCancel}
          onOk={handleOk}
        />
      </Modal>
      <Modal
        title=""
        open={openEditModel}
        onOk={handleOk}
        onCancel={() => {
          handleEditCancel();
        }}
        width={650}
        footer={null}
      >
        <TeamEditModal
          fetchData={fetchData}
          originalFormData={originalFormData}
          formData={formData}
          setformData={setformData}
          changeHandeler={changeHandeler}
          handleCancel={handleEditCancel}
          onOk={handleOk}
          openEditModel={openEditModel}
          handleSubmit={handleSubmit}
          loading={loading}
          setLoading={setLoading}
        />
      </Modal>
    </div>
  );
};
