import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Scatter,
} from "recharts";

const GoalCharts = ({
  COLORS,
  pieChartImg,
  scatterChartImg,
  handlePieChartDownload,
  handleScatterChartDownload,
  statsData,
  pieChartRef,
  scatterChartRef,
  chartsWrapperRef,
}) => {
  const circleData = [
    {
      name: "Completed",
      value: statsData?.goal_progress?.completed_percentage,
    },
    {
      name: "In Progress",
      value: statsData?.goal_progress?.in_progress_percentage,
    },
    {
      name: "Not Started",
      value: statsData?.goal_progress?.not_started_percentage,
    },
  ];

  console.log(statsData, circleData);

  const bubbleData = Object.entries(statsData?.goal_quarter_progress ?? {}).map(
    ([quarter, progress], index) => ({
      quarter, 
      goalsCompleted: progress?.completed_percentage,
      x: index + 1, // Convert index to numeric x-axis
      y: progress?.completed_percentage, // Completed percentage
      z: progress?.completed, // Number of completed goals
    })
  );

  console.log(bubbleData);

  useEffect(() => {
    if (statsData && pieChartRef.current && scatterChartRef.current) {
      handlePieChartDownload();
      handleScatterChartDownload();
    }
  }, [statsData]);

  return (
    <div>
      <div
        className="charts-wrapper flex space-x-8 mb-4"
        ref={chartsWrapperRef}
      >
        <div className="chart-container bg-white flex-1" ref={pieChartRef}>
          <h3 className="text-center font-medium my-4">Goal Status</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <defs>
                {COLORS.map((color, index) => (
                  <linearGradient
                    id={`gradient${index}`}
                    key={index}
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="1"
                  >
                    <stop offset="0%" stopColor={color?.start} />
                    <stop offset="100%" stopColor={color?.end} />
                  </linearGradient>
                ))}
              </defs>
              <Pie
                data={circleData}
                dataKey="value"
                nameKey="name"
                innerRadius={60}
                outerRadius={100}
                paddingAngle={5}
              >
                {circleData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(#gradient${index})`} />
                ))}
              </Pie>
              <Tooltip />
              <Legend
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-container bg-white flex-1" ref={scatterChartRef}>
          <h3 className="text-center font-medium my-4">
            Quarterly Goal Completion
          </h3>
          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="x"
                name="Quarter"
                domain={[0.5, 4.5]}
                tickFormatter={(tick) => `Q${tick}`}
              />
              <YAxis
                type="number"
                dataKey="y"
                name="Completion Percentage"
                label={{
                  value: "Completion %",
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Legend />
              <Scatter
                name="Goal Completion"
                data={bubbleData}
                fill="#4F46E5"
                shape="circle"
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* <div className="mt-4 flex space-x-3">
        {pieChartImg && <img src={pieChartImg} alt="Pie Chart" />}
        {scatterChartImg && (
          <img
            src={scatterChartImg}
            alt="Scatter Chart"
            style={{ marginTop: "20px" }}
          />
        )}
      </div> */}
    </div>
  );
};

export default GoalCharts;
