import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart,
} from "recharts";

const ObjectiveChart = ({
  barData,
  stackedBarData,
  combinedChartImg,
  setCombinedChartImg,
}) => {
  const chartsWrapperRef = useRef(null);

  const handleCombinedChartDownload = async () => {
    console.log("Executing some function before downloading PDF");
    if (chartsWrapperRef.current) {
      try {
        const canvas = await html2canvas(chartsWrapperRef.current);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const url = URL.createObjectURL(blob);
        setCombinedChartImg(url);
      } catch (error) {
        console.error("Error capturing combined charts:", error);
      }
    } else {
      console.error("Chart wrapper element is not available in the DOM.");
    }
  };

  return (
    <div>
      <div
        className="charts-wrapper  gap-8 mb-4" // Using CSS Grid for side-by-side layout
        ref={chartsWrapperRef}
      >
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={stackedBarData} stackOffset="expand">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {barData.map((bar, index) => (
              <Bar
                key={index}
                dataKey={bar.dataKey}
                stackId="a"
                fill={bar.fill}
                name={bar.name}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      <button
        onClick={handleCombinedChartDownload}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Combined Chart Image
      </button>
    </div>
  );
};

export default ObjectiveChart;
