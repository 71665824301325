import React from "react";
import "animate.css";
import {
  Box,
  Card,
  Divider,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function CardFeatures() {
  return (
    <>
      <div id="features" className="mt-64">
        <Heading
          as="h2"
          fontSize={{ base: "2xl", md: "4xl" }}
          color={"#14285a"}
          textAlign={"center"}
          className="mb-5"
        >
          Features
        </Heading>
        <Container maxW={"5xl"} py={12} paddingRight={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16}>
            <Box position="relative" borderTopRadius="30px" mb={4}>
              <Card
                borderRadius={"30px"}
                border={"3px solid"}
                borderColor={"#9DC8DE"}
                minH={"500px"}
                spacing={4}
                padding={4}
              >
                <Box
                  position="absolute"
                  top="0%"
                  left="15%"
                  transform="translate(-50%, -50%)"
                  zIndex={1}
                  boxSize="70px"
                  border="2px solid white"
                  bg="white"
                  className="flex items-center justify-center"
                >
                  <Image
                    loading="lazy"
                    decoding="asynchronous"
                    src="/assets/images/feature1.png"
                    alt="AI-Powered OKR"
                    boxSize="60px"
                  />
                </Box>
                <Box
                  padding={4}
                  minH="430px"
                  className="flex flex-col justify-evenly"
                  m={3}
                  mt={5}
                  backgroundColor={"#F6FAFC"}
                  borderRadius={"30px"}
                >
                  <Heading
                    textAlign={"left"}
                    fontSize={{ base: "2xl", md: "4xl" }}
                    className="font-poppins"
                  >
                    AI-Powered OKR System
                  </Heading>
                  <Text className="text-base font-normal">
                    Experience the power of artificial intelligence in
                    optimizing your goal-setting and performance management
                    processes with our AI-powered OKR system. Our innovative
                    platform leverages cutting-edge AI algorithms to automate
                    key aspects of the OKR process, providing efficiency,
                    insights, and personalized recommendations tailored to your
                    organization's unique needs.
                    <Link
                      to="/resources"
                      className="text-blue-500 underline ml-2"
                    >
                      Learn More
                    </Link>
                  </Text>
                </Box>
              </Card>
            </Box>

            <Box position="relative" borderTopRadius="30px" mb={4}>
              <Card
                borderRadius={"30px"}
                border={"3px solid"}
                borderColor={"#FFAE60"}
                minH={"500px"}
                spacing={4}
                padding={4}
              >
                <Box
                  position="absolute"
                  top="0%"
                  left="15%"
                  transform="translate(-50%, -50%)"
                  zIndex={1}
                  boxSize="70px"
                  border="2px solid white"
                  bg="white"
                  className="flex items-center justify-center"
                >
                  <Image
                    loading="lazy"
                    decoding="asynchronous"
                    src="/assets/images/feature2.png"
                    alt="Automated OKRs"
                    boxSize="60px"
                  />
                </Box>
                <Box
                  padding={4}
                  minH="430px"
                  className="flex flex-col justify-evenly "
                  m={3}
                  mt={5}
                  backgroundColor={"#FFFBF7"}
                  borderRadius={"30px"}
                >
                  <Heading
                    textAlign={"left"}
                    fontSize={{ base: "2xl", md: "4xl" }}
                    className="font-poppins"
                  >
                    Automated OKRs
                  </Heading>
                  <Text className="text-base font-normal ">
                    Maximize Your Performance Insights with Seamless
                    Integrations. Experience the power of data-driven
                    decision-making with our platform's seamless integrations
                    with third-party data sources. By automating OKRs and KPIs
                    through data integration, we empower organizations to unlock
                    valuable insights and drive better results.
                    <Link
                      to="/resources"
                      className="text-blue-500 underline ml-2"
                    >
                      Learn More
                    </Link>
                  </Text>
                </Box>
              </Card>
            </Box>
          </SimpleGrid>
        </Container>

        <Container maxW={"5xl"} py={12} paddingRight={4}>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={16}>
            <Box position="relative" borderTopRadius="30px" mb={4}>
              <Card
                borderRadius={"30px"}
                border={"3px solid"}
                borderColor={"#FFD991"}
                minH={"500px"}
                spacing={4}
                padding={4}
              >
                <Box
                  position="absolute"
                  top="0%"
                  left="15%"
                  transform="translate(-50%, -50%)"
                  zIndex={1}
                  boxSize="70px"
                  border="2px solid white"
                  bg="white"
                  className="flex items-center justify-center"
                >
                  <Image
                    loading="lazy"
                    decoding="asynchronous"
                    src="/assets/images/feature3.png"
                    alt="Synergy"
                    boxSize="60px"
                  />
                </Box>
                <Box
                  padding={4}
                  minH="430px"
                  className="flex flex-col justify-evenly"
                  m={3}
                  mt={5}
                  backgroundColor={"#FFFCF7"}
                  borderRadius={"30px"}
                >
                  <Heading
                    textAlign={"left"}
                    fontSize={{ base: "2xl", md: "4xl" }}
                    className="font-poppins"
                  >
                    Synergy And Collaboration
                  </Heading>
                  <Text className="text-base font-normal">
                    Empower your teams to achieve greater synergy and
                    collaboration with our OKR platform. By implementing
                    Objectives and Key Results (OKRs), organizations can unlock
                    the potential of teamwork and drive collective success. Our
                    feature-rich solution leverages powerful tools like
                    reminders, notifications, and tags to enable teams to align
                    objectives, track progress, and achieve success together.
                    <Link
                      to="/resources"
                      className="text-blue-500 underline ml-2"
                    >
                      Learn More
                    </Link>
                  </Text>
                </Box>
              </Card>
            </Box>

            <Box position="relative" borderTopRadius="30px" mb={4}>
              <Card
                borderRadius={"30px"}
                border={"3px solid"}
                borderColor={"#F4BEDA"}
                minH={"500px"}
                spacing={4}
                padding={4}
              >
                <Box
                  position="absolute"
                  top="0%"
                  left="15%"
                  transform="translate(-50%, -50%)"
                  zIndex={1}
                  boxSize="70px"
                  border="2px solid white"
                  bg="white"
                  className="flex items-center justify-center"
                >
                  <Image
                    loading="lazy"
                    decoding="asynchronous"
                    src="/assets/images/feature4.png"
                    alt="Insights"
                    boxSize="60px"
                  />
                </Box>
                <Box
                  padding={4}
                  minH="430px"
                  className="flex flex-col justify-evenly "
                  m={3}
                  mt={5}
                  backgroundColor={"#FFF9FC"}
                  borderRadius={"30px"}
                >
                  <Heading
                    textAlign={"left"}
                    fontSize={{ base: "2xl", md: "4xl" }}
                    className="font-poppins"
                  >
                    Executive Insights
                  </Heading>
                  <Text className="text-base font-normal ">
                    Our platform empowers executives with unparalleled insights
                    into organizational performance, offering a bird's-eye view
                    of strategic objectives and their corresponding outcomes.
                    With AI summarization features, real-time data, and
                    intuitive dashboards, leaders can track key metrics,
                    identify trends, and make informed decisions to steer the
                    company toward success.{" "}
                    <Link
                      to="/resources"
                      className="text-blue-500 underline ml-2"
                    >
                      Learn More
                    </Link>
                  </Text>
                </Box>
              </Card>
            </Box>
          </SimpleGrid>
        </Container>
      </div>

      <Box bg={"#F7F7F6"} my={12}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box textAlign="center">
            <Heading
              as="h3"
              fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
              fontWeight="semibold"
              mb={4}
              textAlign="center"
            >
             Integrate With All Your Favorite Apps
            </Heading>
          </Box>
          <Flex
            alignItems="center"
            justify={{ base: "center", md: "space-between" }}
            mx={{ base: "2", md: "10", lg: "20" }}
            my={4}
            wrap="wrap" // Ensure wrapping is enabled
          >
            {[
              {
                src: "/assets/images/google_calendar.png",
                alt: "google_calendar",
              },
              {
                src: "/assets/images/google_analytics.png",
                alt: "google_analytics",
              },
              { src: "/assets/images/JIRA.png", alt: "jira" },
              { src: "/assets/images/trelloicon.png", alt: "trello" },
              { src: "/assets/images/Asanalogo.png", alt: "asana" },
            ].map((icon, index) => (
              <React.Fragment key={icon.alt}>
                <Box mx={{ base: 1, lg: 4 }} my={2} flex="0 1 auto">
                  <Image
                    loading="lazy"
                    decoding="async"
                    src={icon.src}
                    alt={icon.alt}
                    maxWidth={{ base: "30px", md: "50px", lg: "60px" }}
                  />
                </Box>
                {index < 4 && (
                  <Box
                    mx={{ base: 1, lg: 4 }}
                    display={{ base: "none", md: "flex" }} // Hide dividers on small screens
                    alignItems="center"
                  >
                    <Divider
                      orientation="vertical"
                      borderColor="#011824"
                      borderWidth="1px"
                      height={{ base: "30px", md: "40px", lg: "50px" }}
                    />
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
