import React from "react";
import "../styles/Forgetpassword.css";
import { useState } from "react";
import { baseURL } from "../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../components/global/api";

export default function ForgetPassword() {
  const [formData, setformData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  function changeHandeler(event) {
    const { name, value } = event.target;
    setformData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const saveData = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await api.post(baseURL + "/forget-password/", {
        email: formData.email,
      });
      if (result.status === 200) {
        toast.success("Password Reset Link Sent To Your Email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Email Does'nt Exist");
      }
     
    } catch (err) {
      // console.log(err);
      toast.error("Email Does'nt Exist", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div
        className="rounded-md shadow-lg shadow-gray-300 "
        style={{ padding: "32px 24px" }}
      >
        <h2>Reset your password</h2>
        <form action="/login" className="flex flex-col items-center w-full">
          <div className="flex flex-col items-center ">
            <div style={{ marginTop: "10PX" }}>
              <label className="font-medium" style={{ color: "#347E96" }}>
                Request an email reset link
              </label>
              <br />
              <div style={{ position: "relative" }}>
                <svg
                  width="13"
                  height="11"
                  viewBox="0 0 13 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "calc(50%)",
                    transform: "translateY(-25%)",
                    zIndex: "1",
                  }}
                >
                  <path
                    d="M1.5 10.5C1.15625 10.5 0.861979 10.3776 0.617188 10.1328C0.372396 9.88802 0.25 9.59375 0.25 9.25V1.75C0.25 1.40625 0.372396 1.11198 0.617188 0.867188C0.861979 0.622396 1.15625 0.5 1.5 0.5H11.5C11.8438 0.5 12.138 0.622396 12.3828 0.867188C12.6276 1.11198 12.75 1.40625 12.75 1.75V9.25C12.75 9.59375 12.6276 9.88802 12.3828 10.1328C12.138 10.3776 11.8438 10.5 11.5 10.5H1.5ZM6.5 6.125L11.5 3V1.75L6.5 4.875L1.5 1.75V3L6.5 6.125Z"
                    fill="#AFB0B6"
                  />
                </svg>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={changeHandeler}
                  value={formData.email}
                  placeholder="Enter Email Address"
                  required
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#F8F9F9",
                    border: "none",
                  }}
                />
              </div>
            </div>
            <div className="w-full">
              <button
                className="forget_button"
                type="submit"
                onClick={saveData}
                disabled={loading}
              >
                {loading ? (
                  <img
                    src="/assets/images/WhiteLoader.svg"
                    alt="loader"
                    className="w-8 h-8"
                  />
                ) : (
                  "Send link"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
