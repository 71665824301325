import React, { useEffect, useRef, useState } from "react";
import { ChakraProvider, Box, Text, Flex, VStack } from "@chakra-ui/react";
import { baseURL } from "../utils/config";
import { useDispatch, useSelector } from "react-redux";
import EastIcon from "@mui/icons-material/East";
import { api } from "./global/api";
import dayjs from "dayjs";
import { formatDate } from "../utils/helpers";
import { Link } from "react-router-dom";

const UpcomingTouchBases = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const today = dayjs().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(today);
  const user = useSelector((state) => state?.user);
  const roleAccess = user?.roleAccess;

  const fetchData = async () => {
    const currentCompany = user?.currentUser?.employees[user?.currentCompany];
    try {
      const response = await api.get(baseURL + `/participationschedule-get/`, {
        participation_id: currentCompany?.user_id,
        company_id: currentCompany?.company_id,
      });

      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ChakraProvider>
      <Box className="p-4">
        <Text className="text-lg font-semibold">Upcoming TouchBases</Text>
        <Text className="text-gray-500 text-sm ">{formatDate()}</Text>

        <Box className="space-y-4  mt-4">
          <VStack spacing={4}>
            {data["Today"] && data["Today"].length > 0 ? (
              data["Today"]?.slice(0, 2)?.map((meeting) => (
                <Box
                  key={meeting.id}
                  w="100%"
                  p={4}
                  border="1px solid #E2E8F0"
                  borderRadius="md"
                  boxShadow="sm"
                  _hover={{ boxShadow: "lg", bg: "gray.50" }}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="lg" fontWeight="bold" color="teal.700">
                      {meeting?.name}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {new Date(meeting?.upcoming_date).toLocaleString(
                        "en-US",
                        {
                          dateStyle: "medium",
                          timeStyle: "short",
                        }
                      )}
                    </Text>
                  </Flex>
                  <Text mt={2} fontSize="sm" color="gray.500">
                    Hosted by: {meeting?.user_details?.username}
                  </Text>
                </Box>
              ))
            ) : (
              <Text className="text-gray-500 text-center mt-2">
                No Meetings Scheduled For Today.
              </Text>
            )}
          </VStack>
          {data["Today"]?.length > 0 && (
            <Link to={"/company/touchbase"} className="flex justify-end mt-2">
              <EastIcon className="text-gray-600" />
            </Link>
          )}
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default UpcomingTouchBases;
