import React, { useState } from "react";
import { useSelector } from "react-redux";
import Pricing from "../Pricing";
import { baseURL } from "../../utils/config";
import { api }  from "./api";

const PricingModal = ({ widthNew }) => {
  const user = useSelector((state) => state?.user);
  const currentPlan = user?.currentPlan;
  const currentCompany = user?.currentUser?.employees[user?.currentCompany];
  const [loading, setLoading] = useState(false);

  const upgradePlan = async ({ price_id }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("user_id", currentPlan?.user_id);
      const res = await api.post(
        `${baseURL}/create-customer-portal-session/`,
        {
          user_id: currentPlan?.user_id,
          price_id: price_id,
          company_id: currentCompany?.company_id,
        }
      );
      const data = res.data;
      window.location.href = data.url;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full flex items-center justify-center bg-white rounded-lg overflow-y-auto">
      <div className="flex flex-col gap-y-2 mt-4 overflow-x-hidden">
        <h2 className="text-3xl text-center font-semibold">
          Upgrade Your Plan
        </h2>
        <p className="text-center">
          Choose a plan that best fits your needs. You can upgrade or downgrade
          at any time.
        </p>
        <p className="text-center text-orange-500 ">* 14-Days free trial !</p>
        <Pricing widthNew={widthNew} handleClick={upgradePlan} />
      </div>
    </div>
  );
};

export default PricingModal;
