import { Modal, Tooltip, TreeSelect } from "antd";
import React, { useEffect, useState } from "react";
import { GoGoal } from "react-icons/go";
import {
  handleCloseKeyresultDrawer,
  handleCloseMoveKeyResultModal,
} from "../../../stores/slices/okrSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../global/api";

const MoveKeyResultModal = ({ goalName, drawerData, getOkrs }) => {
  const [formData, setformData] = useState({});
  const [parentDropdownItems, setParentDropdownItems] = useState([]);
  const okrSlice = useSelector((state) => state.okr);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const getParentData = async () => {
    try {
      const currentEmployee = currentUser?.employees[user?.currentCompany];
      const response = await api.get(baseURL + "/assignparents/", {
        company_id: currentEmployee?.company_id,
        session: okrSlice?.session,
      });
      const tempParentTree = response.data?.map((goal) => {
        return {
          label: (
            <Tooltip title="Please select a parent Okr.">
              <p className="flex gap-x-2 items-center">
                <button
                  className="rounded p-1"
                  style={{
                    background:
                      "linear-gradient(140.34deg, rgba(252, 8, 0, 0.29) 9.12%, rgba(253, 64, 64, 0) 92.07%)",
                  }}
                >
                  <GoGoal
                    className="text-md"
                    style={{
                      color: "#B91C1C",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                </button>
                {goal?.title}
              </p>
            </Tooltip>
          ),
          value: goal?.goal_id + " " + "goal",
          type: "goal",
          disabled: true,
          children: goal?.children?.map((okr) => ({
            label: (
              <p className="flex gap-x-2 items-center">
                <button>
                  <img
                    src="/assets/images/objective.svg"
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </button>
                {okr?.title}{" "}
              </p>
            ),
            value: okr?.okr_id + " " + "okr",
            type: "okr",
            disabled: okr?.okr_id === drawerData?.okr_id,
          })),
        };
      });
      setParentDropdownItems(tempParentTree);
    } catch (error) {}
  };

  useEffect(() => {
    if (okrSlice?.openMoveKeyResultModal) getParentData();
  }, [okrSlice?.openMoveKeyResultModal]);

  const moveGoal = async () => {
    const key_id = drawerData?.key_id;
    const moveData = formData?.parent_val;
    const splitMoveData = moveData?.split(" ");
    if (splitMoveData?.length !== 2) return;

    const parent_id = splitMoveData[0];
    const parent_type = splitMoveData[1];
    try {
      const response = await api.post(baseURL + "/move-jobs/", {
        current_id: key_id,
        current_type: "key_result",
        target_id: parent_id,
        target_type: parent_type,
      });
      getOkrs();
    } catch (error) {
      console.log(error);
    }
    dispatch(handleCloseMoveKeyResultModal(false));
    dispatch(handleCloseKeyresultDrawer(false));
  };
  const checkTypeAndSave = (newVal) => {
    if (!newVal) return;
    const splitData = newVal?.split(" ");
    if (splitData?.length !== 2) return;
    if (splitData[1] !== "okr") {
      toast.warn("Please Select An Okr");
      return;
    }
    const parent_id = splitData[0];
    const parent_type = splitData[1];
    setformData({
      ...formData,
      parent_val: newVal,
      parent_id: parent_id,
      parent_type: parent_type,
    });
  };

  return (
    <Modal
      zIndex={3}
      open={okrSlice?.openMoveKeyResultModal}
      onCancel={() => dispatch(handleCloseMoveKeyResultModal(false))}
      okText="Move"
      onOk={moveGoal}
    >
      <div className="w-full grid grid-cols-6 gap-x-4 gap-y-4 p-6">
        <h3 className="col-span-6 text-2xl font-semibold ">Move Okr</h3>
        <div className="col-span-6 flex gap-x-2 items-center">
          <button>
            <img
              src="/assets/images/keyresults.svg"
              style={{
                width: "27px",
                height: "27px",
              }}
            />
          </button>
          {drawerData?.title}
        </div>
        <div className="col-span-6 flex flex-col gap-2 mt-2 w-full">
          <div className="col-span-6 text-xs">Move To</div>
          <TreeSelect
            showSearch
            size="large"
            className="w-full"
            value={formData?.parent_val}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Assign Parent"
            allowClear
            treeDefaultExpandAll
            onChange={(newVal) => checkTypeAndSave(newVal)}
            treeData={parentDropdownItems}
          />
        </div>
      </div>
      {okrSlice?.openMoveKeyResultModal && <ToastContainer />}
    </Modal>
  );
};

export default MoveKeyResultModal;
