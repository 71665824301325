import React, { useEffect, useState, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { baseURL } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import ChatInput from "../Chatbot/ChatInput";
import MessageList from "../Chatbot/MessageList";
import Cookies from "js-cookie";
import axios from "axios";
import { api, apiService } from "./api";
import { useSelector } from "react-redux";

const ChatBotComponent = ({ data, summarize, setSummarize, handleClose }) => {
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const scrollRef = useRef(null);
  const user = useSelector((state) => state.user);
  const currentUser = user?.currentUser;
  const currentEmployee = currentUser?.employees[user?.currentCompany];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (parseInt(file?.size) <= 1048576) {
      setFileUpload(file);
    } else {
      toast.error("This file is too large. The maximum size is 1MB.",{ containerId: "specific" }, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      const { scrollHeight, clientHeight } = scrollRef.current;
      const maxScrollTop = scrollHeight - clientHeight;
      scrollRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleMessage = async (newQuery) => {
    try {
      setLoading(true);
      const formData = new FormData();
      const token = Cookies.get("access_token") || null;
      if (data) {
        formData.append("goal_id", data?.goal_id);
      }
      formData.append("query", newQuery);
      formData.append("company_id", currentEmployee?.company_id);
      const result = await api.postpayload(baseURL + "/messenger/", formData);
      let jsonData;
      if (result?.data?.response_type === "response") {
        jsonData = result?.data?.message;
      } else {
        jsonData = result?.data?.query ? result?.data?.query : result?.data;
      }

      const parseMarkdown = (text) => {
        if (typeof text !== "string") return text;

        return text.split(/\n/).map((line, index) => {
          // Handle headers
          if (line.startsWith("###")) {
            return (
              <h3 key={index} style={{ margin: "8px 0" }}>
                {line.slice(3).trim()}
              </h3>
            );
          } else if (line.startsWith("##")) {
            return (
              <h2 key={index} style={{ margin: "12px 0" }}>
                {line.slice(2).trim()}
              </h2>
            );
          } else if (line.startsWith("#")) {
            return (
              <h1 key={index} style={{ margin: "16px 0" }}>
                {line.slice(1).trim()}
              </h1>
            );
          }

          // Handle bold text
          line = line.replace(/\*\*(.*?)\*\*/g, (_, match) => {
            return `<strong>${match}</strong>`;
          });

          // Handle italic text
          line = line.replace(/\*(.*?)\*/g, (_, match) => {
            return `<em>${match}</em>`;
          });

          // Handle inline code (if you want to support code formatting)
          line = line.replace(/`(.*?)`/g, (_, match) => {
            return `<code>${match}</code>`;
          });

          // Return formatted text with potential HTML
          return (
            <p
              key={index}
              style={{ margin: "4px 0" }}
              dangerouslySetInnerHTML={{ __html: line }}
            />
          );
        });
      };

      const formattedResponse = parseMarkdown(jsonData);

      setMessages([
        ...messages,
        { query: newQuery, response: formattedResponse },
      ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setMessages([
        ...messages,
        { query: newQuery, response: "Try again later." },
      ]);
    }
  };

  const handleSummary = () => {
    setOpenModal(false);
    const newQuery = "Generate the summary for the selected Goal.";
    setMessages([...messages, { query: newQuery, response: "" }]);
    handleMessage(newQuery);
    setQuery("");
    setSummarize(false);
  };

  const handleGoal = () => {
    setOpenModal(false);
    const newQuery = "help me to generate a new Goal";
    setMessages([...messages, { query: newQuery, response: "" }]);
    handleMessage(newQuery);
    setQuery("");
  };

  useEffect(() => {
    if (summarize) {
      handleSummary();
    }
  }, []);

  const handleQueries = (e) => {
    setQuery(e.target.value);
  };

  const handleSendMessage = () => {
    const queryType = "summary";
    setMessages([...messages, { query: query, response: "" }]);
    handleMessage(query, queryType);
    setQuery("");
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleDoc = () => {
    setFileUpload();
    setOpenModal(!openModal);
  };

  const handleUploadDoc = async () => {
    if (fileUpload) {
      setMessages([...messages, { query: fileUpload, response: "" }]);
      try {
        setLoading(true);
        setOpenModal(false);
        const formData = new FormData();
        if (data) {
          formData.append("goal_id", data.goal_id);
        }
        formData.append("file_upload", fileUpload);
        formData.append("company_id", currentEmployee?.company_id);
        const result = await api.postpayload(baseURL + "/messenger/", formData);

        const jsonData = result?.data?.query
          ? result?.data?.query
          : result?.data;
        setMessages([...messages, { query: fileUpload, response: jsonData }]);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        const message = error?.response?.data?.error;
        setLoading(false);
        setMessages([...messages, { query: fileUpload, response: message }]);
      }
    }
  };

  return (
    <div
      className="text-black bg-white z-auto rounded-md mb-2 mr-5 shadow-xl"
      style={{
        width: "80vw", // Full width for empty messages on smaller screens
        minWidth: "200px",
        maxWidth: "500px",
        height: window.innerWidth <= 768 ? "70vh" : "87vh",
        maxHeight: "87vh",
        transition:
          "width 1s cubic-bezier(0.42, 0, 0.58, 3), height 1s cubic-bezier(0.42, 0, 0.58, 3)",
        ...(messages?.length === 0 && {
          backgroundImage: "url(/assets/images/bgChatbot.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }),
      }}
    >
      <div className="flex flex-col justify-between rounded-md h-full">
        <div
          className="flex justify-between bg-basic text-white items-center gap-x-3 p-4"
          style={{
            borderRadius: "6px 6px 0px 0px",
            height: "10vh",
          }}
        >
          <span className="flex gap-x-1 font-bold text-xl">
            <div className="flex items-center gap-x-2">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.41051 2.47379V4.66619L4.82584 4.89507C3.5902 5.38294 3.63239 5.35283 3.42745 5.87683C3.33101 6.1298 3.16224 6.62972 3.05375 6.98508L2.85484 7.63557L1.94468 7.93673C0.913973 8.28004 0.684927 8.43062 0.443825 8.91247L0.287109 9.22567V11.5867C0.287109 13.0985 0.31122 14.014 0.353412 14.1224C0.467935 14.4236 0.727119 14.7488 0.974248 14.8934C1.11288 14.9717 1.55892 15.1464 1.97482 15.2789C2.39072 15.4174 2.7584 15.5439 2.79456 15.574C2.83073 15.5981 2.95128 15.8992 3.05978 16.2486C3.16224 16.5979 3.33101 17.0858 3.42745 17.3448L3.60828 17.8025L4.16884 18.0495C9.67198 20.4647 15.591 20.4587 21.1243 18.0435L21.6788 17.8025L21.8597 17.3448C21.9561 17.0858 22.1249 16.5919 22.2334 16.2365L22.4323 15.586L23.1857 15.3391C24.0959 15.0379 24.4696 14.8512 24.6745 14.5802C25 14.1525 25 14.1465 25 11.5927V9.22567L24.8433 8.91247C24.6022 8.43062 24.3731 8.28004 23.3424 7.93673L22.4323 7.63557L22.2334 6.98508C22.1249 6.62972 21.9561 6.1298 21.8597 5.87683C21.6788 5.41908 21.6728 5.41306 21.3353 5.25646C21.1484 5.17213 20.7385 4.99746 20.4372 4.877L19.8766 4.66017V2.47379V0.287416L18.8097 0.299462L17.7489 0.317531L17.3571 2.07025L16.9713 3.81694L16.4047 3.70852C13.9937 3.24475 11.0161 3.25679 8.68949 3.73864L8.31578 3.81694L7.93002 2.07025L7.53823 0.317531L6.47739 0.299462L5.41051 0.287416V2.47379ZM14.1203 5.5275C15.6935 5.66 17.4776 6.0515 18.9544 6.58756C20.0815 6.99713 20.0032 6.93087 20.2262 7.63557C21.0339 10.1894 21.0339 12.9419 20.2322 15.5499C20.0032 16.2968 20.1056 16.2124 18.9363 16.628C16.7845 17.399 14.9762 17.7122 12.6737 17.7182C10.9076 17.7182 9.61773 17.5616 8.03249 17.152C7.35138 16.9713 6.01327 16.5317 5.52504 16.3209L5.2538 16.2004L5.05489 15.5499C4.2472 12.9359 4.25323 10.1954 5.06092 7.63557L5.2538 7.0152L5.53106 6.90076C7.14644 6.20208 9.46102 5.64796 11.3778 5.50943C12.0046 5.46124 13.4512 5.47329 14.1203 5.5275Z"
                  fill="white"
                />
                <path
                  d="M8.09285 8.32203C7.85778 8.42442 7.67092 8.63523 7.55037 8.91229C7.47804 9.09298 7.45996 9.4122 7.45996 10.5987C7.45996 11.8937 7.47804 12.0925 7.57448 12.3093C7.85778 12.9297 8.68355 13.1224 9.18384 12.6767C9.56357 12.3454 9.5696 12.3214 9.5696 10.5747C9.5696 9.0448 9.56357 8.98456 9.43096 8.74966C9.18986 8.32203 8.55697 8.11724 8.09285 8.32203Z"
                  fill="white"
                />
                <path
                  d="M16.2842 8.35183C16.1456 8.41206 15.9708 8.55661 15.8924 8.66503C15.7478 8.86981 15.7478 8.88788 15.7297 10.49C15.7176 11.6284 15.7357 12.1644 15.7839 12.2849C15.8924 12.5499 16.2722 12.8571 16.5494 12.9053C17.0377 12.9836 17.5138 12.7427 17.7127 12.309C17.8092 12.0922 17.8273 11.8934 17.8273 10.5864C17.8273 9.27938 17.8092 9.08062 17.7127 8.86981C17.4475 8.2916 16.8749 8.08681 16.2842 8.35183Z"
                  fill="white"
                />
                <path
                  d="M10.5339 14.9535V15.7666H12.6436H14.7532V14.9535V14.1404H12.6436H10.5339V14.9535Z"
                  fill="white"
                />
                <path
                  d="M9.7865 21.9162C7.59851 22.0005 5.35627 22.1812 3.12005 22.4583C2.17373 22.5727 0.715064 22.7835 0.419715 22.8498C0.287109 22.8739 0.287109 22.8859 0.287109 23.9339V25L0.546293 24.9578C4.67515 24.2833 8.28565 23.9941 12.6737 23.9941C16.7242 23.9941 19.7982 24.217 23.5293 24.7711C24.2948 24.8856 24.9337 24.9819 24.9578 24.9819C24.9819 24.9819 25 24.5121 25 23.9339C25 22.9823 24.9879 22.8799 24.8975 22.8498C24.8373 22.8317 24.2224 22.7353 23.5233 22.639C19.0569 21.9945 14.3192 21.7475 9.7865 21.9162Z"
                  fill="white"
                />
              </svg>
              <p className="font-semibold text-lg">Joyce</p>
            </div>
            {data && (
              <p className="font-semibold text-lg">
                {": " + data?.title || ": " + data?.goal_name}
              </p>
            )}
          </span>
          <span className="flex justify-evenly items-center gap-x-3">
            <span
              className="close text-xl font-bold"
              onClick={handleClose}
              style={{
                cursor: "pointer",
                color: "white",
                transition: "background-color 0.3s, color 0.3s",
                width: "15px",
                height: "15px",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#D3D3D3";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "transparent"; // Restore default background color
                e.target.style.color = "inherit"; // Restore default text color
              }}
            >
              <IoMdClose style={{ fontWeight: "bold" }} />
            </span>
          </span>
        </div>
        <div
          className="overflow-y-scroll"
          style={{
            height: "100%",
            scrollBehavior: "smooth",
          }}
          ref={scrollRef}
        >
          <MessageList
            messages={messages}
            data={data}
            handleSummary={handleSummary}
            loading={loading}
            handleDoc={handleDoc}
            handleGoal={handleGoal}
            openModal={openModal}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setOpenModal={setOpenModal}
            handleFileChange={handleFileChange}
            handleUploadDoc={handleUploadDoc}
            selectedFileName={fileUpload}
          />
        </div>

        <ChatInput
          handleEnterKeyPress={handleEnterKeyPress}
          handleQueries={handleQueries}
          openModal={openModal}
          query={query}
          loading={loading || isEditing}
          handleSendMessage={handleSendMessage}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChatBotComponent;
