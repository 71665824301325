import React, { useState, useEffect } from "react";
import { Button, Select } from "antd";
import { baseURL } from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { EditorState } from "draft-js";
import { getAvatarColor, getInitials } from "../../../utils/helpers";
import { handleUpdatePlan } from "../../../stores/slices/userAuthSlice";
import { api } from "../../global/api";
import { toast } from "react-toastify";

const TeamModal = ({
  handleCancel,
  handleOk,
  formData,
  setformData,
  changeHandeler,
  fetchData,
}) => {
  const [owner, setOwner] = useState([]);
  const [ownerError, setOwnerError] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);
  const user = useSelector((state) => state?.user);
  const currentCompany = useSelector((state) => state.user?.currentCompany);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/employee_details/", {
          company_id: company_id,
        });
        setOwner(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  const getCurrentPlanData = async () => {
    try {
      const currentCompany = user?.currentUser?.employees[user?.currentCompany];
      const result = await api.get(baseURL + "/subscription/", {
        company_id: currentCompany?.company_id,
      });

      dispatch(handleUpdatePlan(result.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(baseURL + "/create-team/", {
        team_name: formData?.name,
        company_id: currentUser?.employees[currentCompany]?.company_id,
        users: formData?.members,
      });
      if (res.status === 200) {
        getCurrentPlanData();
        toast.success("Team Created Successfully");
      } else {
        toast.error("Please Try Again");
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCancel();
      fetchData();
      setformData({
        owner: "",
        name: "",
      });
      setTimeout(() => {
        toast.dismiss();
      }, 1000);
    }

    setLoading(false);
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  return (
    <div>
      <form
        className="'w-full flex flex-col py-3 px-3 gap-y-4"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col items-start w-full gap-y-2">
          <h1 className="p-1 text-3xl font-bold text-black">Create Team</h1>
          <p className="p-1 text-gray-600">
            All required fields are marked with an asterisk (*).
          </p>
        </div>

        {/* {showBelowDiv && ( */}
        <div className="w-full grid grid-cols-6 gap-x-6 py-2 my-3 gap-y-6 items-center max-h-[350px] overflow-y-auto">
          <label className="col-span-2 text-sm">Name*</label>

          <div className="col-span-4 ">
            <input
              className="w-full px-2 py-1 text-sm border border-gray-300 rounded-lg"
              type="text"
              name="name"
              id="name"
              maxLength={25}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value !== " " &&
                  /[a-zA-Z0-9]/.test(value) &&
                  !/^[\s]+$/.test(value)
                ) {
                  changeHandeler(e);
                } else if (value === "") {
                  changeHandeler(e);
                }
              }}
              value={formData.name}
              placeholder="Enter A Team Name"
              required
            />
          </div>

          <label className="col-span-2 text-sm">Members</label>
          <div className="col-span-4 ">
            <Select
              name="members"
              id="members"
              onChange={(val) => {
                // Map selected user_id back to the corresponding role from the owner list
                const selectedMembers = val.map((userId) => {
                  const selectedOwner = owner.find((o) => o.user_id === userId);
                  return {
                    user_id: userId,
                    role_id: selectedOwner?.role,
                  };
                });
                setformData({ ...formData, members: selectedMembers });
                setOwnerError(false);
              }}
              value={formData?.members?.map((member) => member?.user_id)}
              mode="multiple"
              required
              className="w-full"
              placeholder="Select Member(s)"
              tagRender={({ label, value, onClose }) => {
                const selectedOwner = owner.find((o) => o.user_id === value);

                return (
                  <div className="flex items-center gap-x-2">
                    <div
                      className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                      style={{
                        backgroundColor: selectedOwner?.profile_image
                          ? "initial"
                          : getAvatarColor(selectedOwner?.username),
                      }}
                    >
                      {selectedOwner?.profile_image ? (
                        <img
                          src={selectedOwner?.profile_image}
                          alt="Profile"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                            display: "block",
                          }}
                        />
                      ) : (
                        getInitials(selectedOwner?.username)
                      )}
                    </div>
                    <span>{selectedOwner?.username}</span>
                    <button onClick={onClose} className="ml-2">
                      &times;
                    </button>
                  </div>
                );
              }}
            >
              {owner?.map((owner, index) => {
                return (
                  <Select.Option key={index} value={owner?.user_id}>
                    {" "}
                    {/* Use user_id as the value */}
                    <div className="flex items-center gap-x-2">
                      <div
                        className="w-5 h-5 text-sm border border-black rounded-full flex items-center justify-center text-white relative"
                        style={{
                          backgroundColor: owner?.profile_image
                            ? "initial"
                            : getAvatarColor(owner?.username),
                        }}
                      >
                        {owner?.profile_image ? (
                          <img
                            src={owner?.profile_image}
                            alt="Profile"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                              display: "block",
                            }}
                          />
                        ) : (
                          getInitials(owner?.username)
                        )}
                      </div>
                      <span>{owner?.username}</span>
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
          </div>

          <div className="flex gap-5">
            <Button
              loading={loading}
              className="bg-button hover:shadow-lg text-white"
              onClick={handleOk}
              htmlType="submit"
            >
              Create Team
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default TeamModal;
