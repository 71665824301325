import React, { useEffect, useRef, useState } from "react";
import CustomScrollbar from "../../components/global/CustomScrollBar";
import GoalCharts from "./OverallReports/GoalCharts";
import {
  Document,
  Image,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ObjectiveChart from "./OverallReports/ObjectiveChart";
import GanttChart from "./OverallReports/KeyGantChart";
import { api } from "../../components/global/api";
import { baseURL } from "../../utils/config";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#F7F8FA",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  image: {
    width: "100%",
    height: "auto",
  },
  grid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 4,
    marginBottom: 20,
  },
  gridItem: {
    width: "23%", // Roughly 4 items per row
    backgroundColor: "#fff",
    padding: 15,
    textAlign: "center",
    borderWidth: 1, // Simulate shadow with border
    borderColor: "#e0e0e0", // Light gray border color
    borderRadius: 8, // Rounded corners
    marginBottom: 10, // Spacing between rows
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(145deg, #f5f5f5, #e0e0e0)",
  },
  icon: {
    marginRight: 8,
  },
  title: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#4A4A4A",
  },
  value: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#333",
  },
});

const ChartsPDF = ({ pieChartImg, scatterChartImg, metrics, objChartImg }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={{ marginBottom: 20 }}>Executive Dashboard</Text>
        <View style={styles.grid}>
          {metrics?.map((metric, index) => (
            <View key={index} style={styles?.gridItem}>
              <Text style={styles?.title}>{metric?.label}</Text>
              <Text style={styles?.value}>{metric?.value}</Text>
            </View>
          ))}
        </View>

        <Text style={{ marginBottom: 20 }}>Goals Overview</Text>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          {pieChartImg && (
            <Image
              src={pieChartImg}
              style={[styles.image, { flex: 1, marginRight: 10 }]}
            />
          )}
          {scatterChartImg && (
            <Image
              src={scatterChartImg}
              style={[styles.image, { flex: 1, marginLeft: 10 }]}
            />
          )}
        </View>
        <Text style={{ marginBottom: 20 }}>Objective Summary</Text>
        {objChartImg ? (
          <Image src={objChartImg} style={styles.image} />
        ) : (
          <Text>No Image Available</Text>
        )}
      </View>
    </Page>
  </Document>
);

const ExportReports = () => {
  const pieChartRef = useRef(null);
  const scatterChartRef = useRef(null);
  const chartsWrapperRef = useRef(null);
  const [objChartImg, setObjChartImg] = useState("");
  const [pieChartImg, setPieChartImg] = useState("");
  const [scatterChartImg, setScatterChartImg] = useState();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const currentUser = user?.currentUser;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentEmployee = currentUser?.employees;
        let company_id;
        if (currentEmployee && currentEmployee?.length) {
          company_id = currentEmployee[user?.currentCompany]?.company_id;
        }
        const response = await api.get(baseURL + "/insight/overall-report", {
          company_id: company_id,
        });
        console.log("helo", response);
        const data = response?.data;
        setData(data);
        setMetrics([
          { label: "Total Goals", value: data?.total_goal },
          { label: "Total Objectives", value: data?.total_okr },
          { label: "Total Key Results", value: data?.total_key_result },
          { label: "Total Teams", value: data?.total_teams },
        ]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handlePieChartDownload = async () => {
    if (pieChartRef.current) {
      try {
        const canvas = await html2canvas(pieChartRef.current);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const url = URL.createObjectURL(blob);
        setPieChartImg(url);
      } catch (error) {
        console.error("Error capturing pie chart:", error);
      }
    }
  };

  const handleScatterChartDownload = async () => {
    if (scatterChartRef.current) {
      try {
        const canvas = await html2canvas(scatterChartRef.current);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));
        const url = URL.createObjectURL(blob);
        setScatterChartImg(url);
      } catch (error) {
        console.error("Error capturing scatter chart:", error);
      }
    }
  };

  const goalData = [
    { name: "Goal 1", start: 0, progress: 50 },
    { name: "Goal 2", start: 20, progress: 30 },
    { name: "Goal 3", start: 40, progress: 40 },
  ];

  const barData = [
    { dataKey: "completed", fill: "#00C49F", name: "Completed" },
    { dataKey: "inProgress", fill: "#FFBB28", name: "In Progress" },
    { dataKey: "notStarted", fill: "#FF8042", name: "Not Started" },
  ];

  const COLORS = [
    { start: "#4F46E5", end: "#6B67F6" },
    { start: "#FFBB28", end: "#FFCC5A" },
    { start: "#FF8042", end: "#FF9563" },
    { start: "#00C49F", end: "#33D4B3" },
    { start: "#FF4D4D", end: "#FF7070" },
  ];

  const stackedBarData = [
    { name: "Quarter 1", completed: 70, inProgress: 20, notStarted: 10 },
    { name: "Quarter 2", completed: 50, inProgress: 30, notStarted: 20 },
    { name: "Quarter 3", completed: 80, inProgress: 15, notStarted: 5 },
    { name: "Quarter 4", completed: 60, inProgress: 25, notStarted: 15 },
  ];

  return (
    <div className="relative h-full">
      <CustomScrollbar>
        <div className="flex flex-col w-full h-full mb-12">
          <div className="flex justify-between">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Executive Dashboard
            </h2>
            <div>
              <PDFDownloadLink
                document={
                  <ChartsPDF
                    pieChartImg={pieChartImg}
                    scatterChartImg={scatterChartImg}
                    metrics={metrics}
                    objChartImg={objChartImg}
                  />
                }
                fileName="Comprehensive_Reports.pdf"
              >
                {({ loading }) => (
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    disabled={loading}
                  >
                    {loading ? "Generating PDF..." : "Export PDF"}
                  </button>
                )}
              </PDFDownloadLink>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {metrics?.map((metric, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 text-center transition-all duration-300 transform hover:scale-105"
              >
                <h3 className="text-lg font-medium text-gray-600 mb-3">
                  <i className="fas fa-chart-pie mr-2"></i>
                  {metric?.label}
                </h3>
                <p className="text-3xl font-bold text-gray-800 mt-2">
                  {metric?.value}
                </p>
              </div>
            ))}
          </div>

          <GoalCharts
            statsData={data}
            COLORS={COLORS}
            pieChartRef={pieChartRef}
            scatterChartRef={scatterChartRef}
            chartsWrapperRef={chartsWrapperRef}
            setPieChartImg={setPieChartImg}
            setScatterChartImg={setScatterChartImg}
            handlePieChartDownload={handlePieChartDownload}
            handleScatterChartDownload={handleScatterChartDownload}
          />

          <ObjectiveChart
            barData={barData}
            stackedBarData={stackedBarData}
            combinedChartImg={objChartImg}
            setCombinedChartImg={setObjChartImg}
          />
          <GanttChart goalData={goalData} COLORS={COLORS} />
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default ExportReports;
